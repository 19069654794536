import React, { useState, useEffect } from "react";
import "./youtube.css";

const Youtube = () => {
  const videos = [
    "3DUTbfJOndg?si=htvPO36ZmjvRCJXg",
    "zqzF606-7OE?si=4IvnSv7z0Tl61cKI",
    "pLXH0tY2_PQ?si=UMGNgwMQG23MijoQ",
    "iBtK8DyIZ88?si=L43QUd9WY6K1qrp1",
    "ipUKMav-32o?si=YRdq9UMq7--GhZNB",
    "zfWIlcdGHOI?si=yA_ngYFDS_C2fIAe",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

 
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (videos.length / 3)); // Only 2 sets of videos
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + (videos.length / 3)) % (videos.length / 3));
  };

  const startIndex = currentIndex * 3;

  return (
    <div className="slider-container">
      <h1>Find us on Youtube</h1>
      <div className="slider">
        <div
          className="slides"
          style={{ transform: `translateX(${-startIndex * (100 / 3)}%)` }}
        >
        
          {[...Array(3)].map((_, slideIndex) => (
            <div className="slide" key={slideIndex}>
              <div className="video-group">
                {videos.slice(0, 3).map((videoId, index) => (
                  <iframe
                    key={index}
                    width="560"
                    height="400" 
                    src={`https://www.youtube.com/embed/${videoId}`}
                    frameBorder="0"
                    allowFullScreen
                    title={`video-${slideIndex * 3 + index}`}
                  ></iframe>
                ))}
              </div>
            </div>
          ))}
        </div>
        <button className="arrow left" onClick={handlePrev}>&lt;</button>
        <button className="arrow right" onClick={handleNext}>&gt;</button>
      </div>
      <div className="dots">
        {[...Array(2)].map((_, index) => (
          <span
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Youtube;
