import React, { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container mx-auto py-14 mt-20 md:mt-0">
      <div className="contact mb-16">
        <h3 className="font-bold text-slate-700 text-2xl mb-10 ml-5 md:ml-0 tracking-wider">
          Contact Us
        </h3>
        
        <div className="w-full md:flex justify-center md:space-x-10">
          <div className="vision md:w-1/2 text-center mb-10 md:mb-0 bg-blue-100 rounded-lg py-5">
            <h4 className="font-bold text-2xl text-[#2E3192] mb-5">
              BRANCH OFFICE
            </h4>
            <div   className="address">
            <p className="font-bold mb-2">ADDRESS :</p>
              <p className="mb-2 font-medium tracking-wide text-slate-600">
                 Dwarka Nagar, Bengaluru, Karnataka 560063
              </p>
            </div>
            <div className="mobile mb-5">
              <p className="font-bold mb-2">Mobile :</p>
              <p className="mb-2 font-medium tracking-wide text-slate-600">
               +91 8123476244,+91 93420 33334
              </p>
              <p className="font-medium tracking-wide text-slate-600">
              +91 95909 13334
              </p>
            </div>
            <div className="email">
              <p className="font-bold mb-2">Email :</p>
              <p className="mb-2 font-medium tracking-wide text-slate-600">
              enquiry@letceducation.in
              </p>
            </div>
          </div>
          <div className="vision md:w-1/2 text-center bg-blue-100 rounded-lg py-5">
            <h4 className="font-bold text-2xl text-[#2E3192] mb-3">
              CORPORATE OFFICE
            </h4>
            <div className="address">
            <p className="font-bold mb-2">ADDRESS :</p>
              {/* <p className="font-bold mb-2">Corporate Office :</p> */}
              <p className="mb-2 font-medium tracking-wide text-slate-600">
                #6, Kalathur Building, Gangamma Circle, Jalahalli, Bangalore -
                560013
              </p>

              <div className="mobile mb-5">
              <p className="font-bold mb-2">Mobile :</p>
              <p className="mb-2 font-medium tracking-wide text-slate-600">
                +91 9342580693, +91 95909 13334
              </p>
              <p className="font-medium tracking-wide text-slate-600">
              +91 6364229091,9606145581
              </p>
             </div>

             <div className="email">
              <p className="font-bold mb-2">Email :</p>
              <p className="mb-2 font-medium tracking-wide text-slate-600">
                info@letceducation.in
              </p>
            </div>
               
            </div>
          </div>
        </div>

        <br/>
        <br/>
        <br/>
        <div style={{display:'flex', flexDirection:'row'}}>
        <div style={{width:'600px'}} className="map w-full flex justify-center mb-16">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.6491473043106!2d77.61298957067226!3d13.121398983329671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19198c64d229%3A0x3d3d4a920909962d!2sL.E.T.C(Luminous%20Educational%20Training%20Centre)!5e0!3m2!1sen!2sin!4v1706085076464!5m2!1sen!2sin"
            width="500px"
            height="300"
            style={{ border: "0px" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded-3xl w-fit md:w-full"
          ></iframe>
        </div>
        <div  style={{width:'600px',marginLeft:'70px'}} className="map w-full flex justify-center mb-16">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.670400634759!2d77.54450867478187!3d13.05663961300878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22b3163351bb%3A0xef143d060f5ecf06!2sL.E.T.C!5e0!3m2!1sen!2sin!4v1706006465785!5m2!1sen!2sin"
            width="500px"
            height="300"
            style={{ border: "0px" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded-3xl w-fit md:w-full"
          ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
