import React from "react";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import {
  FaSquareFacebook,
  FaSquareTwitter,
  FaSquareYoutube,
} from "react-icons/fa6";
import { Link } from "react-router-dom";

const NavTop = () => {
  return (
    <div className="bg-slate-100 w-full py-1 hidden md:block">
      <div className="container mx-auto flex justify-between">
      <div className="socials flex space-x-5 text-black text-xl">
          {/* <Link to="https://www.facebook.com/LETCBANGALORE" target="_blank">
            <FaSquareFacebook className="hover:text-black" />
          </Link> */}
          
        </div>
        <div className="contacts flex space-x-10 text-black font-medium">
          <div className="flex">
            <FaPhoneSquareAlt className="text-2xl mr-2" />
            <p className="tracking-wider text-base">+91 93420 33334</p>
          </div>
          {/* <div className="flex">
            <MdEmail className="text-2xl mr-2" />
            <p className="tracking-wider text-base">Letcedu4@gmail.com</p>
          </div> */}
          <div className="flex">
            <MdEmail className="text-2xl mr-2" />
            <p className="tracking-wider  text- 3xl text-base">info@letceducation.in</p>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default NavTop;
