import React from "react";
import logo from "../Assests/logo.jpg";
import { Link } from "react-router-dom";
import { FaPhoneSquareAlt } from "react-icons/fa";
import {
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareTwitter,
  FaSquareYoutube,
} from "react-icons/fa6";
const Footer = () => {
  return (
    <>
      <div className="bg-indigo-900 w-full h-[450px] hidden md:block py-10 pl-10">
        <div className="w-full h-full flex space-x-20">
          <div className="w-1/3 h-full">
            <div className="img flex justify-center">
              <img
                src={logo}
                alt="..."
                className="w-16 rounded-sm mb-4 object-top"
              />
            </div>
            <div className="text-center text- text-white tracking-wide mb-12">
              <p className="mb-3 text-lg">
                Our aim is to assist students to develop positive attitude,
                competitive mind and special skills in clearingNational/state
                level entrance exams of reputed Engineering & Medical Colleges.
              </p>
            </div>
            <div className="flex justify-center">
      <div className="socials flex space-x-6 text-white text-4xl">
        <Link to="https://www.facebook.com/LETCBANGALORE" target="_blank" rel="noopener noreferrer">
          <FaSquareFacebook className="hover:text-blue-600 transition-colors duration-300" />
        </Link>
        <Link to="https://www.instagram.com/letc2009/" target="_blank" rel="noopener noreferrer">
          <FaSquareInstagram className="hover:text-pink-500 transition-colors duration-300" />
        </Link>
        <Link to="https://www.youtube.com/@letcedu4" target="_blank" rel="noopener noreferrer">
          <FaSquareYoutube className="hover:text-red-600 transition-colors duration-300" />
        </Link>
      </div>
    </div>
    <br/>
            <div className="link-items text-lg text-white flex justify-center">
              <ul className="flex space-x-5">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/result">Result</Link>
                </li>
                <li>
                  <a href="#course">Courses</a>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-1/3 h-full text-white pl-16">
            <div className="contact">
              <h4 className="font-bold text-xl text-white tracking-wide mb-5">
                Contact with Us
              </h4>
            </div>
            <div className="contact-items text-xs text-white mb-10">
              <div className="contact mb-5">
                <p className="mb-1 font-bold text-lg text-white">
                  Mobile :
                </p>
                <p className="tracking-wide text-lg text-white">
                  9342580693, 6364229091,9590913334
                </p>
              </div>
              <div className="contact mb-5">
                <p className="mb-1 font-bold text-lg text-white">Email :</p>
                <p className="tracking-wide text-lg text-white">
                  enquiry@letceducation.in<br/>
                  info@letceducation.in
                </p>
              </div>
              <div className="corporate mb-5">
                <p className="mb-1 font-bold text-lg text-white">
                  Corporate Office :
                </p>
                <p className="tracking-wide text-lg text-white">
                  #6, Kalathur Building, Gangamma Circle, Jalahalli,
                  Bangalore - 560013
                </p>
              </div>
            </div>
          </div>
          <div className="w-1/3 h-full flex justify-center items-center">
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.670400634759!2d77.54450867478187!3d13.05663961300878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22b3163351bb%3A0xef143d060f5ecf06!2sL.E.T.C!5e0!3m2!1sen!2sin!4v1706006465785!5m2!1sen!2sin"
                width="250"
                height="200"
                style={{ border: "0px",marginTop:'17px' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="rounded-3xl"
              ></iframe>
            </div>
          </div>
        </div>
        
        <div className="rights flex justify-center text-white tracking-wide text-sm">
          <p>
            © 2009-2024 LETC Pvt. Ltd. |All Rights Reserved
            <br/>
            <a style={{textAlign:'center'}} href="https://rcsinfo.in">
            Powered by RCS Technology.
            </a>
          </p>
        </div>
      </div>
      {/*For Mobile View */}
      <div className="w-full md:hidden flex justify-center">
        <div className="mobile-footer bg-[#1E2434] py-10">
          <div className="logo text-center mb-10">
            <div className="logo-img flex justify-center mb-5">
              <img src={logo} alt="logo" className="w-16 rounded" />
            </div>
            <p className="font-light text-white">
              Our aim is to assist students to develop positive attitude,
              competitive mind and special skills in clearingNational/state
              level entrance exams of reputed Engineering & Medical Colleges.
            </p>
          </div>
          {/* <div className="flex justify-center mb-10">
            <div className="quick-links">
              <h4 className="font-bold text-xl tracking-wide mb-5 text-white">
                Quick Links
              </h4>
              <div className="link-items text-base text-slate-400">
                <ul className="text-center">
                  <li className="mb-5">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="mb-5">
                    <Link to="/about">About</Link>
                  </li>
                  <li className="mb-5">
                    <Link to="/result">Result</Link>
                  </li>
                  <li className="mb-5">
                    <Link to="/faculty">Faculty</Link>
                  </li>
                  <li className="mb-5">
                    <Link to="/gallery">Gallery</Link>
                  </li>
                  <li className="mb-5">
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>*/}
          <div className="flex justify-center mb-10">
            <div className="text-white text-center">
              <div className="contact">
                <h4 className="font-bold text-xl tracking-wide mb-5">
                  Contact with Us
                </h4>
              </div>
              <div className="contact-items text-xs text-slate-400 mb-10">
                <div className="contact mb-10">
                  <p className="mb-1 font-bold text-lg">Mobile :</p>
                  <p className="tracking-wide text-base">
                  9342580693, 6364229091,<br/> 9590913334
                  </p>
                </div>
                <div className="contact mb-10">
                  <p className="mb-1 font-bold text-lg">Email :</p>
                  <p className="tracking-wide text-base">
                  enquiry@letceducation.in<br/>
                  info@letceducation.in
        
                  </p>
                  <br/>
                 
                </div>
                <div className="corporate mb-10">
                  <p className="mb-1 font-bold text-lg">Corporate Office :</p>
                  <p className="tracking-wide text-base">
                    #6, Kalathur Building, Gangamma Circle, Jalahalli,<br></br>
                    Bangalore - 560013
                  </p>
                </div>
              </div>
              <div className="map flex justify-center">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.670400634759!2d77.54450867478187!3d13.05663961300878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22b3163351bb%3A0xef143d060f5ecf06!2sL.E.T.C!5e0!3m2!1sen!2sin!4v1706006465785!5m2!1sen!2sin"
                  width="280"
                  height="220"
                  style={{ border: "0px" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="rounded-3xl"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="rights flex justify-center text-white tracking-wide text-sm">
          <p>
            © 2009-2024 LETC Pvt. Ltd. |All Rights Reserved
            <br/>
            <a style={{textAlign:'center',paddingLeft:'40px'}} href="https://rcsinfo.in">
            Powered by RCS Technology.
            </a>
          </p>
        </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
